import Skeleton from "@material-ui/lab/Skeleton";
import classNames from "classnames";
import React from "react";

import VoidPlaceholder from "~/components/core/VoidPlaceholder";
import { REQUEST_STATE } from "~/constants/apiInteraction";

import ErrorPlaceholder from "../ErrorPlaceholder";
import { SkeletonError } from "../StatisticCard/StatisticCardSkeleton";

interface Props {
  hasData?: boolean;
  requestState?: REQUEST_STATE;
  resetFilters?: () => void;
}

export const WidgetContentNew: React.FC<{
  hasData: boolean | number | undefined | null;
  loading: boolean;
  error?: Error;
  resetFilters?: () => void;
  className?: string;
  testid?: {
    range: React.TestID;
    noData?: React.TestID;
    resetFilters?: React.ButtonTestID;
    resources?: React.ButtonTestID;
    error: React.TestID;
  };
}> = ({
  children,
  loading,
  hasData,
  error,
  resetFilters,
  className,
  testid
}) => {
  if (error || loading) {
    return (
      <Skeleton
        className={classNames(skeletonClasses, className)}
        animation={error ? false : "pulse"}
      >
        {error && <SkeletonError isChart testid={testid?.error} />}
      </Skeleton>
    );
  }
  if (!hasData) {
    return (
      <VoidPlaceholder
        resetFilters={resetFilters}
        className={className}
        testid={testid}
      />
    );
  }
  return <>{children}</>;
};

const WidgetContent: React.FC<Props> = ({
  children,
  requestState,
  hasData,
  resetFilters
}) => {
  switch (requestState) {
    case REQUEST_STATE.init:
    case REQUEST_STATE.loading:
      return <Skeleton className={skeletonClasses} />;
    case REQUEST_STATE.error:
      return <ErrorPlaceholder />;
    default:
      if (hasData) {
        return <>{children}</>;
      } else {
        return <VoidPlaceholder resetFilters={resetFilters} />;
      }
  }
};

export default WidgetContent;

export const skeletonClasses =
  "h-[calc(100%-16px)] flex items-center justify-center bg-cream-300 mx-[32px] mb-[16px] transform-none rounded-[8px]";
